
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Articles from '../Articles';
import Loading from '../loading';
import Districts from './Districts';
import NewImage from '../Modals/NewImage';

const Home = ({ loading, loadingPosts, loadingDistricts, posts, currentUser, dispatchPosts, districts, banner, setBanner, socket }) => {

    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: 0});
            window.scrollTo(0, 0);
            setReload(false);
        }
    }, [setBanner, setReload, banner, reload]);

    return (
        <Container fluid className='pt-3'>
            {(!loadingDistricts) ? (
            <Row className='cursor-standard'>
                <Districts 
                    districts={districts}
                    socket={socket}
                    currentUser={currentUser}
                />
            </Row>
            ) : (
                <Loading/>
            )}
            {(!loadingPosts) ? (
                <Row className='cursor-standard'>
                    <Col>
                        <Articles
                            isLoading={loading}
                            data={posts}
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            type='home'
                            socket={socket}
                        />
                    </Col>
                </Row>
            ) : (
                <Loading/>
            )}
            <NewImage
                currentUser={currentUser}
                banner={banner}
                setBanner={setBanner}
                socket={socket}
            />
        </Container>
    );
    
};

export default Home