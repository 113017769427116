import './App.css';
import React, { Fragment } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Main from './components/Main';
import 'react-quill/dist/quill.snow.css';
import "react-quill/dist/quill.core.css";
import Author from './components/Author';

const author = window.location.href.toLowerCase().includes("author");

const App = () => {
  return (
    <Fragment>
      {author ? <Author /> : (
        <Router>
          <Main />
        </Router>
      )}      
    </Fragment>
  );
};

export default App;
