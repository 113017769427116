import React from 'react';
import { Container } from 'react-bootstrap';
import k4avgLogo from '../images/K4AVGwebapps.webp'

const Footer = ({error, setError}) => {

    const handleClose = () => {
        setError({...error, show: false});
    }
    
    return (
        
                <Container fluid className='mt-5'>
                    <div className="row text-center d-flex justify-content-center">
                        <div className="col pt-2 mb-3">
                            East Tennessee SKYWARN
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-1">
                                <a href="https://www.facebook.com/groups/etskywarn/" target="_blank" className="nav-link active">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                </svg>
                                </a>
                            </div>
                            <div className="col-md-1">
                            <a href="https://twitter.com/etskywarn" target="_blank" className="nav-link active">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                </svg>
                            </a>
                            </div>
                        </div>
                        <br/>
                    </div>
                    <hr />
                    <div className="row text-center d-flex justify-content-center">
                        <div className="col-md-3 col-sm-12">
                            <div>
                                <h4>Site Links</h4>
                            </div>
                            <a className="nav-link no-button" href="/">Home</a>
                            <a className="nav-link no-button" href="/statements">NWS API</a>
                            <a className="nav-link no-button" href="/gallery">Gallery</a>
                            <a className="nav-link no-button" href="/about">About Us</a>
                            <a className="nav-link no-button" href="https://storymaps.arcgis.com/collections/bd6cffba42de4339981758cecc110e36">Training</a>
                            <a className="nav-link no-button" aria-current="page" data-bs-toggle="modal" href="#Login" data-bs-target="#LoginModal">Login/Register</a>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div>
                                <h4>NWS MRX Links</h4>
                            </div>
                            <a className="nav-link no-button" target="_blank" href="https://www.weather.gov/mrx/nwr">NWS Frequencies</a>
                            <a className="nav-link no-button" target="_blank" href="https://www.weather.gov/mrx/skywarn_classes">Spotter Class Schedule</a>
                            <a className="nav-link no-button" target="_blank" href="https://www.weather.gov/mrx/watchwarnadv">Severe Weather Criteria</a>
                            <a className="nav-link no-button" target="_blank" href="https://www.facebook.com/NWSMorristown">MRX Facebook</a>
                            <a className="nav-link no-button" target="_blank" href="https://twitter.com/NWSMorristown">MRX Twitter</a>
                            <a className="nav-link no-button" target="_blank" href="https://www.youtube.com/NWSMorristown">MRX Youtube</a>
                            <a className="nav-link no-button" href="https://storymaps.arcgis.com/collections/bd6cffba42de4339981758cecc110e36">MRX Training</a>
                        </div>
                        <div className="col-md-3 col-sm-12">
                        <div>
                            <h4>Other Links</h4>
                        </div>
                            <a className="nav-link no-button" target="_blank" href="/author">Author</a>         
                            <a className="nav-link no-button" target="_blank" href="https://www.wate.com/weather/">WATE TV</a>
                            <a className="nav-link no-button" target="_blank" href="https://www.wbir.com/weather">WBIR TV</a>
                            <a className="nav-link no-button" target="_blank" href="https://www.wvlt.tv/weather">WVLT TV</a>
                            <a className="nav-link no-button" target="_blank" href="https://wb4gbi.com">WB4GBI Repeaters</a>
                        
                        </div>
                    </div>
                    <br />
                    <div className="container">
                        <div className="row text-center">
                            <p>© 2024 WX4TYS</p>
                        </div>
                    </div>
                </Container>
    )
};

export default Footer;