import React, { Fragment, useEffect, useReducer, useState } from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import { io } from 'socket.io-client';
import AuthPopup from './Auth/AuthPopup';
import Home from './Home';
import NavCont from './Nav';
import NotFound from './NotFound';
import NWSIndex from './NWS/NWSIndex';
import Error from './Modals/Error';
import DistrictPage from './Districts/Districts';
import Footer from './Footer';
import NewDistrict from './Modals/NewDistrict';
import Nets from './Nets/Index';
import DistrictNets from './Nets/DistrictNets';
import Banner from './Banner';
import About from './About';
import Gallery from './Gallery';
import Settings from './Auth/Settings';
import UserManage from './Auth/UserManage';
import Loading from './loading';
import Docs from './Docs';
import axios from 'axios';
import { Container, Image } from 'react-bootstrap';
import authorImage from '../images/author.webp';
import Author from './Author';
import NewGallery from './NewGallery';

const Main = () => {

    const ENDPOINT = process.env.REACT_APP_SOCKET_URL;

    const socket = io(ENDPOINT, { transports: ['websocket'] });

    const navigate = useNavigate();

    const [showAuth, setShowAuth] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const [ loadingPosts, setLoadingPosts ] = useState(true);
    const [ loadingDistricts, setLoadingDistricts ] = useState(true);
    const [ userChecked, setUserChecked ] = useState(false);

    const [ tickerText, setTickerText] = useState(null);

    const [addDist, setAddDist] = useState(false);

    const [ checkedIp, setCheckedIp ] = useState(false);

    const [currentUser, setCurrentUser] = useState({
        isAuthenticated: false,
        adminSettings: false,
        user: {}
    });

    const [error, setError] = useState({
        show: false,
        type: "",
        message: ""
    });

    const [ banner, setBanner ] = useState({
        id: '0',
        loading: true,
        showModal: false
    });

    const [{ posts }, dispatchPosts] = useReducer((state, action) => {
        if (action.type === 'NEW_POST') {
            console.log(action)
            const newArray = [...state.posts, {...action.data}];
            return {
                ...state,
                posts: newArray
            }
        } else if(action.type === 'UPDATE_POST'){
            const index = state.posts.findIndex(post => post._id === action.data._id);
            const newArray = [...state.posts];
            newArray[index] = action.data;
            return {
                ...state,
                posts: newArray
            }
        } else if(action.type === "LOAD_POSTS"){
            state.posts = action.data;
            return state
        } else if(action.type === "REMOVE_POST"){
            let newArray = state.posts.filter(post => post._id !== action.data._id);
            return {
                ...state,
                posts: newArray
            }
    }
        return state
    }, { posts: [] });

    const [{ districts }, dispatchDistricts] = useReducer((state, action) => {
        if (action.type === 'create') {
            console.log(action);
            const newArray = [...state.districts, action.district];
            return {
                ...state,
                districts: newArray
            }
        } else if(action.type === 'update'){
            const index = state.districts.findIndex(district => district._id === action.district._id);
            const newArray = [...state.districts];
            newArray[index] = action.district;
            return {
                ...state,
                districts: newArray
            }
        } else if(action.type === "get"){            
            state.districts = action.districts;
            return state
        }
        return state
    }, { districts: [] });


    useEffect(() => {
        if (loadingPosts || loadingDistricts) {
    
          if (loadingPosts) {
            socket.emit('get posts');
            socket.on('load posts', (data) => {
              dispatchPosts({ data, type: "LOAD_POSTS" });
              setLoadingPosts(false);
            });
          }
    
          if (loadingDistricts) {
            socket.emit('districts', { type: 'get' });
            socket.on('districts', (data) => {
              dispatchDistricts(data);
              setLoadingDistricts(false);
            });
          }
          
        }
    }, [socket, loadingPosts, loadingDistricts, dispatchPosts, dispatchDistricts, setLoadingPosts, setLoadingDistricts]);

    useEffect(() => {
        if (loading) {
          
            if(!checkedIp){
                axios
                .get(`https://freeipapi.com/api/json`)
                .then((res) => {
                localStorage.setItem('ip', res.data.ipAddress);
                })
                .catch((err) => {
                //   setError({ message: err.message, type: 'danger', show: true });
                });
                setCheckedIp(true);
            }
    
          if (!userChecked && localStorage.getItem('auth_token')) {
            const authToken = localStorage.getItem('auth_token');
            const userId = localStorage.getItem('_id') ?? '';
            socket.emit('user check', { _id: userId, jwt: authToken });
            setUserChecked(true);
    
            socket.on('checked user', (data) => {
              if (localStorage.getItem('ip') === data.ip) {
                localStorage.setItem('auth_token', data.jwt);
                setCurrentUser({ ...currentUser, user: data, isAuthenticated: true });
              }
            });
    
            socket.on('null user', () => {
              localStorage.removeItem('auth_token');
              localStorage.removeItem('_id');
              console.log('nullUser');
              setCurrentUser({
                isAuthenticated: false,
                adminSettings: false,
                user: {},
              });
            });
          }
          setLoading(false);
        }
    }, [socket, currentUser, loading, setUserChecked, setCurrentUser, setError, userChecked, checkedIp]);
    
    useEffect(() => {
        socket.on('districts', async (data) => {
          if (data.type !== 'get') {
            dispatchDistricts(data);
          }
        });
    
        socket.on('new post', (data) => {
          dispatchDistricts({ data, type: 'NEW_POST' });
        });
    
        socket.on('error', (error) => {
          setError({ message: error.message, type: error.type, show: true });
        });

    }, [socket, dispatchDistricts]);

    const handleAddDist = () => {
        setAddDist(true);
    }

    const handleLogout = e => {
        e.preventDefault();
        localStorage.removeItem('auth_token');
        localStorage.removeItem('_id');
        setCurrentUser({
            isAuthenticated: false,
            adminSettings: false,
            user: {}
        });
        socket.emit('user logout', currentUser.user);
        navigate('/', {replace: true});
    }

    return (
        (!loadingPosts && !loadingDistricts) ? (
            <Fragment>
                <Banner 
                    currentUser={currentUser}
                    districts={districts}
                    banner={banner}
                    setBanner={setBanner}
                    loading={loading}
                    socket={socket}
                />
                <div className='sticky-top'>
                    <NavCont
                        showAuth={showAuth}
                        setShowAuth={setShowAuth}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        handleAddDist={handleAddDist}
                        loadingDistricts={loadingDistricts}
                        districts={districts}
                        handleLogout={handleLogout}
                        socket={socket}
                    />
                    <NWSIndex 
                        socket={socket}
                    />
                </div>
                {/*     Routes     */}
                <Routes>
                    <Route exact path='/' element={
                        <Home 
                            loading={loading}
                            loadingPosts={loadingPosts}
                            loadingDistricts={loadingDistricts}
                            posts={posts}
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            districts={districts}
                            banner={banner}
                            setBanner={setBanner}
                            socket={socket}
                        />
                    } />
                    <Route exact path='/district/:id' element={
                        <DistrictPage 
                            districts={districts}
                            posts={posts}
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            banner={banner}
                            setBanner={setBanner}
                            socket={socket}
                        />
                    } />
                    <Route exact path='/district/:id/:page' element={
                        <DistrictPage 
                            districts={districts}
                            posts={posts}
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            banner={banner}
                            setBanner={setBanner}
                            socket={socket}
                        />
                    } />
                    {currentUser.isAuthenticated && (
                        <>
                            <Route exact path='/nets' element={
                                <Nets 
                                    districts={districts}
                                    dispatchDistricts={dispatchDistricts}
                                    banner={banner}
                                    setBanner={setBanner}
                                    socket={socket}
                                />
                            } />
                            <Route exact path='/nets/:id' element={
                                <DistrictNets
                                    districts={districts}
                                    dispatchDistricts={dispatchDistricts}
                                    currentUser={currentUser}
                                    banner={banner}
                                    setBanner={setBanner}
                                    socket={socket}
                                />
                            } />
                            <Route exact path='/documents' element={
                                <Docs 
                                    currentUser={currentUser}
                                    socket={socket}
                                />
                            } />
                            <Route exact path='/settings' element={
                                <Settings 
                                    currentUser={currentUser}
                                    setCurrentUser={setCurrentUser}
                                    socket={socket}
                                />
                            } />
                        </>
                    )}
                    {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8) && (
                        <Route exact path='/users' element={
                            <UserManage 
                                currentUser={currentUser}
                                socket={socket}
                            />
                        } />
                    )}
                    <Route exact path='/loading' element={
                        <Loading />
                    } />
                    <Route exact path='/gallery' element={
                        <NewGallery 
                            currentUser={currentUser}
                            setBanner={setBanner}
                            banner={banner}
                            socket={socket}
                        />
                    } />
                    <Route exact path='/about' element={
                        <About 
                            loading={loading}
                            loadingPosts={loadingPosts}
                            posts={posts}
                            dispatchPosts={dispatchPosts}
                            currentUser={currentUser}
                            banner={banner}
                            setBanner={setBanner}
                            socket={socket}
                        />
                    } />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />

                {/*     Modals     */}
                <AuthPopup 
                    showAuth={showAuth}
                    setShowAuth={setShowAuth}
                    setCurrentUser={setCurrentUser}
                    setError={setError}
                    socket={socket}
                />
                <Error 
                    setError={setError}
                    error={error}
                    socket={socket}
                />
                <NewDistrict
                    show={addDist}
                    setShow={setAddDist}
                    dispatchDistricts={dispatchDistricts}
                    socket={socket}
                />
            </Fragment>
        ) : (
            <Loading />
        )
    );
};

export default Main