
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import Loading from '../loading';
import NewNet from '../Modals/NewNet';
import ViewNet from './ViewNet';
import TableContainerReverseSort from '../Table/TableContainerReverseSort';

const DistrictNets = ({districts, currentUser, setBanner, socket}) => {

    const { id } = useParams();

    const [ loading, setLoading ] = useState(true);

    const [ bannerLoad, setBannerLoad ] = useState(true);
    if(bannerLoad){
        setBanner({loading: false, id: id});
        setBannerLoad(false);
    }
    
    const [ district ] = useState(districts.filter(dist => dist.district.toString() === id)[0]);

    const [ distNet, setDistNet ] = useState({
        show: false,
        data: {}
    });

    const [ newNet, setNewNet ] = useState({
        repeater: "",
        status: "",
        show: false,
        district: district.district,
    });

    const [{ nets }, dispatchNets] = useReducer((state, action) => {
        if (action.type === 'NEW_NET') {
            return {
                ...state,
                nets: [...state.nets.filter(net => net._id !== action.data._id), action.data]
            }
        } else if(action.type === 'UPDATE_NET'){
            const index = state.nets.findIndex(net => net._id === action.data._id);
            const newArray = [...state.nets];
            newArray[index] = action.data;
            return {
                ...state,
                nets: newArray
            }
        } else if(action.type === "LOAD_NETS"){
            state.nets = action.data
            return state
        }
        return state
    }, { nets: [] });

    if(loading){
        let payload = {district: id, ip: localStorage.getItem('ip')};
        socket.emit('load nets', payload);
        socket.on('nets loaded', data => {
            dispatchNets({data, type: "LOAD_NETS"});
            setLoading(false);
        });
    }

    const handleNewNet = e => {
        e.preventDefault();
        let payload = { 
            districtId: district._id, 
            user: currentUser.user, 
            newNet 
        };
        socket.emit('create net', payload);
        setNewNet({...newNet, show: false});
    }
    
    const handleDistNet = (row) => {
        let selectedNet = row.row.original;
        setDistNet({show: true, loading: true, data: selectedNet});
    }

    useEffect(() => {
        socket.on('net created', data => {
            dispatchNets({data, type: "NEW_NET"});
        });
        socket.on('net updated', data => {
            dispatchNets({data, type: "UPDATE_NET"});
        });
    }, [socket, dispatchNets]);

    const columns = useMemo(() => [
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Repeater",
            accessor: "repeater",
        },
        {
            Header: "Activated",
            accessor: "timestamp",
            Cell: e => <Moment format='MM/DD/YYYY - HH:mm'>{e.cell.value}</Moment>
        },
        {
            Header: "Ended",
            accessor: "endTimestamp",
            Cell: e => <Moment format='MM/DD/YYYY - HH:mm'>{e.cell.value}</Moment>
        },
        {
            Header: "View",
            Cell: row =>  <Button onClick={e => handleDistNet(row)} variant='primary' size='sm'>View Net</Button>
        }
    ], []);
    
    return (
        <Container fluid className='mt-5 mb-5'>
            {!loading ? (
                <>
                    {nets.length ? (
                        <>
                            <TableContainerReverseSort columns={columns} data={nets} />
                            {distNet.show && (
                                <ViewNet 
                                    distNet={distNet}
                                    setDistNet={setDistNet}
                                    currentUser={currentUser}
                                    dispatchNets={dispatchNets}
                                    district={district}
                                    socket={socket}
                                />
                            )}
                        </>
                    ) : (
                        <div className='mt-5 text-center'>
                            <h5>No Data Found</h5>
                            <br/>
                            <p>
                                This district has not activated a net in this system. 
                            </p>
                            <p>
                                This does not mean they are inactive.
                            </p>
                            <p>
                                Either they have not, or do not, use our system.
                            </p>
                        </div>
                    )}
                    {((currentUser.isAuthenticated && currentUser.user.isAdmin > 9 && !distNet.show) || 
                    (currentUser.isAuthenticated && currentUser.user.isAdmin.toString() === id && !distNet.show)) && (
                        <NewNet 
                            newNet={newNet}
                            setNewNet={setNewNet}
                            dispatchNets={dispatchNets}
                            district={district}
                            currentUser={currentUser}
                            handleNewNet={handleNewNet}
                            socket={socket}
                        />
                    )}
                </>
            ) : (
                <Loading />
            )}
        </Container>
    );
};

export default DistrictNets