import React, { useState } from 'react';
import { NavItem, NavLink, Modal, Row, Dropdown } from 'react-bootstrap';
import Signin from './Signin';
import Signup from './Signup';

const AuthPopup = ({showAuth, setShowAuth, setCurrentUser, setError, socket}) => {

    const [authType, setAuthType] = useState({
        form: 'Sign In'
    })

    const handleClose = e => {
        setShowAuth(!showAuth);
    }

    const handleAuth = e => {
        console.log(e.target.name);
        setAuthType({
            form: e.target.name
        })
    }

    return (
        <>
            <Modal className='text-black' show={showAuth} >
                <Modal.Header closeButton onClick={handleClose}>
                    <Modal.Title>East Tennessee SKYWARN</Modal.Title>
                </Modal.Header>
                <Modal.Header>
                    <Dropdown as={NavItem}>
                        <Dropdown.Toggle as={NavLink}>{authType.form}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleAuth} name="Sign In">Sign In</Dropdown.Item>
                            <Dropdown.Item onClick={handleAuth} name="Sign Up">Sign Up</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Header>
                <Modal.Body>
                    {
                        authType.form === 'Sign In' ? (
                            <Signin 
                                handleClose={handleClose}
                                setCurrentUser={setCurrentUser}
                                setAuthType={setAuthType}
                                setError={setError}
                                setShowAuth={setShowAuth}
                                socket={socket}
                            />
                        ) : authType.form === 'Sign Up' && (
                            <Signup 
                                handleClose={handleClose}
                                setCurrentUser={setCurrentUser}
                                setAuthType={setAuthType}
                                setError={setError}
                                setShowAuth={setShowAuth}
                                socket={socket}
                            />
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AuthPopup