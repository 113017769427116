import React, { useState } from 'react';
import { Button, Col, FloatingLabel, Modal, Row, Form } from 'react-bootstrap';
import NewImage from './NewImage';

const NewPage = ({socket, district}) => {

    const [ show, setShow ] = useState(false);
    const [ title, setTitle ] = useState(null);

    const handleClose = () => {
        setShow(false);
    }

    const handleSubmit = () => {
        let authToken = localStorage.getItem('auth_token');
        let userId = localStorage.getItem('_id') ? localStorage.getItem('_id') : "";
        let payload = {
            authToken,
            userId,
            page: {
                type: district,
                title
            }
        }
        socket.emit('create page', payload);
        handleClose();
    }
    
    return (
        <>
        <Button onClick={() => setShow(true)}>New Page</Button>
        <Modal className='text-black' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>New Page</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='text-black'>
                    <Col>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Title"
                            className="mb-3"
                        >
                            <Form.Control onChange={e => setTitle(e.target.value)} type="text" />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button onClick={handleSubmit} variant="primary">
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
};

export default NewPage;
