
import React, { useState } from 'react';
import { Button, Container, FloatingLabel, Form, Modal } from 'react-bootstrap';

const NewDistrict = ({show, setShow, dispatchDistricts, socket}) => {

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [districtNumber, setDistrictNumber] = useState(0);

    const handleSubmit = () => {
        socket.emit('districts', {type: 'create', number: districtNumber, ip: localStorage.getItem('ip')});
        socket.on('districts', data => {
            dispatchDistricts(data);
        });
    }

    return (
        <Container fluid>
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>New District</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>
                    <FloatingLabel controlId="floatingTitle" label="District Number">
                        <Form.Control 
                            type="number" 
                            max={8}
                            defaultValue={districtNumber} 
                            onChange={e => setDistrictNumber(e.target.value)}
                        />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="success" onClick={handleSubmit}>
                    Add District
                </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default NewDistrict