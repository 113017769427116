import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Articles from './Articles';
import Loading from './loading';

const About = ({ loading, loadingPosts, posts, currentUser, dispatchPosts, socket, banner, setBanner }) => {

    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: 0});
            setReload(false);
        }
    }, [setBanner, setReload, banner, reload]);

    return (
        <Container fluid className='mt-5'>
            {(!loadingPosts) ? (
                    <Row>
                        <Col>
                            <Articles
                                isLoading={loading}
                                data={posts}
                                dispatchPosts={dispatchPosts}
                                currentUser={currentUser}
                                type='about'
                                socket={socket}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Loading />
                )}
        </Container>
    )
};

export default About;