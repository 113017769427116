import React, { useState } from 'react';
import {Button, Modal} from 'react-bootstrap';
import DistStat from './DistStat';
import NWSIndex from './NWSIndex';

const DistrictInfo = ({district, socket, show, setShow, currentUser}) => {

    const [ showStat, setShowStat ] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    return (
        <Modal size='lg' className='text-black' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>District {district.district}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NWSIndex 
                    socket={socket}
                    districtNum={district.district}
                />
            </Modal.Body>
            <Modal.Footer>
                {((currentUser.user.isAdmin > 8 || currentUser.user.isAdmin === district.district || currentUser.user.isNcs === district.district)) && (
                    <Button onClick={() => setShowStat(true)} variant='primary'>Settings</Button>
                )}
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
            <DistStat
                show={showStat}
                setShow={setShowStat}
                district={district}
                socket={socket}
            />
        </Modal>
    );
};

export default DistrictInfo;
