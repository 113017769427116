import React, { useEffect, useReducer, useState, useCallback } from 'react';
import NWSAlertBar from './NWSAlertBar';
import NWSCanvas from './NWSCanvas';

const NWSIndex = ({ socket }) => {
  const [loading, setLoading] = useState(true);
  const [showCanvas, setShowCanvas] = useState(false);

  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case 'NEW_ALERT':
        return {
          ...state,
          alerts: [...state.alerts, { ...action.data }],
        };
      case 'LOAD_ALERTS':
        return {
          ...state,
          alerts: action.data,
        };
      case 'REMOVE_ALERT':
        return {
          ...state,
          alerts: state.alerts.filter(alert => alert._id !== action.data._id),
        };
      default:
        return state;
    }
  }, []);

  const [{ alerts }, dispatch] = useReducer(reducer, { alerts: [] });

  useEffect(() => {
    if (loading) {
      socket.emit('load alerts');
      socket.on('alerts loaded', data => {
        dispatch({ type: 'LOAD_ALERTS', data });
        setLoading(false);
      });

      return () => {
        socket.off('alerts loaded');
      };
    }
  }, [loading, socket]);

  useEffect(() => {
    const handleNewAlert = data => {
      dispatch({ type: 'NEW_ALERT', data });
    };

    const handleRemoveAlert = data => {
      dispatch({ type: 'REMOVE_ALERT', data });
    };

    socket.on('nws update', handleNewAlert);
    socket.on('remove alert', handleRemoveAlert);

    return () => {
      socket.off('nws update', handleNewAlert);
      socket.off('remove alert', handleRemoveAlert);
    };
  }, [socket]);

  return !loading && alerts.length > 0 && (
    <>
      <NWSAlertBar
        showCanvas={showCanvas}
        setShowCanvas={setShowCanvas}
        alerts={alerts}
        socket={socket}
      />
      <NWSCanvas
        showCanvas={showCanvas}
        setShowCanvas={setShowCanvas}
        alerts={alerts}
        socket={socket}
      />
    </>
  );
};

export default NWSIndex;
