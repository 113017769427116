import React, { useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const NavCont = ({showAuth, setShowAuth, setCurrentUser, currentUser, handleAddDist, districts, handleLogout}) => {

  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

    const handleNav = e => {
        e.preventDefault();
        setExpanded(false);
        navigate(e.target.name, {replace: true})
    }

    const handleAuth = e => {
        setShowAuth(!showAuth);
    }

    const handleAdmin = e => {
        setCurrentUser({...currentUser, adminSettings: !currentUser.adminSettings});
    }

        return (
            <>
                
                    <Navbar expand="lg" bg="dark" variant="dark" expanded={expanded} className="navbar-dark bg-dark">
                        <Container>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbarBtn" onClick={() => setExpanded(!expanded)} />
                            <Navbar.Collapse id="collapsible-navbar-nav" variant="dark" bg="dark" className="justify-content-end">
                                <Nav className="justify-content-end">
                                    <Nav.Link 
                                        onClick={handleNav}
                                        className={window.location.href.split("/")[3] === ('') && 'current-nav-link'}
                                        name="/" active={
                                            window.location.href.split(':3000')[1] === '/' || 
                                            window.location.href.split('.com')[1] === '/' ||
                                            window.location.href.split('.net')[1] === '/' ||
                                            window.location.href.split('.org')[1] === '/'
                                        } 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-fill pb-1" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                                            <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                                        </svg>
                                        Home
                                    </Nav.Link>
                                    {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                                        <NavDropdown 
                                            active={window.location.href.includes('/district')} 
                                            className={window.location.href.includes('/district') && 'current-nav-link'}
                                            title={
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-binoculars-fill pb-1" viewBox="0 0 16 16">
                                                        <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z"/>
                                                    </svg>
                                                    <span>Districts</span>
                                                </>
                                            } id="basic-nav-dropdown"
                                        >
                                            {
                                                districts[0] && (
                                                    districts
                                                    .sort((a, b) => a.district > b.district ? 1 : -1)
                                                    .map(d => (
                                                        <NavDropdown.Item 
                                                            key={d._id}
                                                            name={`/district/${d.district}`} 
                                                            onClick={handleNav}
                                                        >
                                                            District {d.district}
                                                        </NavDropdown.Item>
                                                    ))
                                                )
                                            }
                                            {(currentUser.isAuthenticated && currentUser.user.isAdmin > 9 && currentUser.adminSettings) && (
                                                <>
                                                    <NavDropdown.Item name="/district/add" onClick={handleAddDist} className="text-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square-fill" viewBox="0 0 16 16">
                                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
                                                        </svg>
                                                    </NavDropdown.Item>
                                                </>
                                            )}
                                        </NavDropdown>
                                    
                                        // <Nav.Link 
                                        //     onClick={handleNav} 
                                        //     className={window.location.href.includes('/nets') && 'current-nav-link'}
                                        //     name="/nets" active={window.location.href.includes('/nets')} 
                                        //     aria-current="page"
                                        // >
                                        //     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-broadcast-pin pb-1" viewBox="0 0 16 16">
                                        //         <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM6 8a2 2 0 1 1 2.5 1.937V15.5a.5.5 0 0 1-1 0V9.937A2 2 0 0 1 6 8z"/>
                                        //     </svg>
                                        //     Nets
                                        // </Nav.Link>
                                    )}
                                    
                                    <Nav.Link 
                                        onClick={handleNav} 
                                        className={window.location.href.includes('/gallery') && 'current-nav-link'}
                                        name="/gallery" active={window.location.href.includes('/gallery')} 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-images pb-1" viewBox="0 0 16 16">
                                            <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                            <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z"/>
                                        </svg>
                                        Gallery
                                    </Nav.Link>
                                    {currentUser.isAuthenticated && (
                                        <Nav.Link 
                                            onClick={handleNav} 
                                            className={window.location.href.includes('/documents') && 'current-nav-link'}
                                            name="/documents" active={window.location.href.includes('/documents')} 
                                            aria-current="page"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-medical-fill pb-1" viewBox="0 0 16 16">
                                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm-3 2v.634l.549-.317a.5.5 0 1 1 .5.866L7 7l.549.317a.5.5 0 1 1-.5.866L6.5 7.866V8.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L5 7l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V5.5a.5.5 0 1 1 1 0zm-2 4.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zm0 2h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"/>
                                            </svg>
                                            Documents
                                        </Nav.Link>
                                    )}
                                    <NavDropdown title={
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link-45deg pb-1" viewBox="0 0 14 14">
                                                    <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
                                                    <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
                                                </svg>
                                                <span>MRX</span>
                                            </>
                                        } id="basic-nav-dropdown"
                                    >
                                        <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.weather.gov/mrx/nwr">NWS Frequencies</NavDropdown.Item>
                                        <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.weather.gov/mrx/skywarn_classNamees">Spotter Class Schedule</NavDropdown.Item>
                                        <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.weather.gov/mrx/watchwarnadv">Severe Weather Criteria</NavDropdown.Item>
                                        <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.facebook.com/NWSMorristown">MRX Facebook</NavDropdown.Item>
                                        <NavDropdown.Item target="_blank" rel="noreferrer" href="https://twitter.com/NWSMorristown">MRX Twitter</NavDropdown.Item>
                                        <NavDropdown.Item target="_blank" rel="noreferrer" href="https://www.youtube.com/NWSMorristown">MRX YouTube</NavDropdown.Item>
                                    </NavDropdown>
                                    <Nav.Link 
                                        onClick={handleNav} 
                                        className={window.location.href.includes('/about') && 'current-nav-link'}
                                        name="/about" active={window.location.href.includes('/about')} 
                                        aria-current="page"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-square pb-1" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z"/>
                                        </svg>
                                        About
                                    </Nav.Link>
                                    {currentUser.isAuthenticated ? (
                                        <NavDropdown title={
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear-fill pb-1" viewBox="0 0 16 16">
                                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                            </svg>
                                            <span>{currentUser.user.username}</span>
                                            </>
                                        } id="basic-nav-dropdown"
                                        >
                                            <NavDropdown.Item name={`/settings`} onClick={handleNav}>Settings</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                            {currentUser.user.isAdmin > 0 && (
                                                <>
                                                    <NavDropdown.Divider />
                                                    { currentUser.user.isAdmin > 8 && (
                                                        <>
                                                            <NavDropdown.Item name='/users' onClick={handleNav}>User Admin</NavDropdown.Item>
                                                            <NavDropdown.Divider />
                                                        </>
                                                    )}
                                                    <Form.Check 
                                                        className="ms-2"
                                                        type="switch"
                                                        id="admin-switch"
                                                        label="Admin"
                                                        value={currentUser.adminSettings}
                                                        onChange={handleAdmin}
                                                    />
                                                </>
                                            )}
                                        </NavDropdown>
                                    ) : (
                                        <Nav.Link className="nav-link" active={showAuth} onClick={handleAuth} >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-open pb-1" viewBox="0 0 16 16">
                                                <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
                                                <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
                                            </svg>
                                            Login/Register
                                        </Nav.Link>
                                    )}
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <a href="https://www.facebook.com/groups/etskywarn/" rel="noreferrer" target="_blank" className="nav-link active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                            </svg>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="https://twitter.com/etskywarn" target="_blank" rel="noreferrer" className="nav-link active">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter" viewBox="0 0 16 16">
                                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                
            </>
        );
}

export default NavCont;
                                