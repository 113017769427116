import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import axios from 'axios';
import ForgotPassword from '../Modals/ForgotPassword';

const Signin = ({setCurrentUser, setShowAuth, setError, socket}) => {

    const [data, setData] = useState({
        username: "",
        password: "",
        geoData: [],
        ip: ""
    });

    const [ forgotPassword, setForgotPassword ] = useState(false);

    const [ geoLoc, setGeoLoc ] = useState(true);

    if(geoLoc){
        axios.get(`https://freeipapi.com/api/json`)
        .then(res => {
            setData({...data, geoData: {...res.data, dateTime: Date.now()}, ip: res.data.ipAddress});
            localStorage.setItem('ip', res.data.ipAddress);
            setGeoLoc(false);
        })
        .catch(err => {
            setGeoLoc(false);
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        socket.emit("user login", data);
        socket.on('login user', data => {
            localStorage.setItem('auth_token', data.jwt);
            localStorage.setItem('_id', data._id);
            setCurrentUser({user: data, isAuthenticated: true});
            setShowAuth(false);
        });
        socket.on('error', data => {
            setError({...data, show: true});
        });
    }

    return (
        !geoLoc && (
            <Container>
                <Form onSubmit={handleSubmit} >
                    <div className="mb-3">
                        <label className="form-label">Callsign</label>
                        <input type="text" name="username" onChange={e => {setData({...data, username: e.target.value.toUpperCase()})}} value={data.username} className="form-control shadow" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input type="password" name="password" onChange={e => {setData({...data, password: e.target.value})}} value={data.password} className="form-control shadow" required />
                    </div>
                    <div>
                        <button type='button' onClick={() => setForgotPassword(true)} className='no-button button-link mb-3'>Forgot Password</button>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Button type='submit' className='btn-block'>Sign In</Button>
                    </div>
                </Form>
                <ForgotPassword 
                    existingUsername={data.username}
                    forgotPassword={forgotPassword}
                    setForgotPassword={setForgotPassword}
                    socket={socket}
                />
            </Container>
        )
    )
}

export default Signin