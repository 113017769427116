
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Container, Nav, Row } from 'react-bootstrap';
import NewEditor from '../TextEditor/NewEditor';
import Editor from '../TextEditor/Editor';
import Active from '../../images/red.png';
import Inactive from '../../images/green.png';
import Standby from '../../images/yellow.png';
import DistrictSettings from '../Modals/DistrictSettings';
import DistNav from './DistNav';

const DistrictPage = ({currentUser, districts, dispatchPosts, posts, banner, setBanner, socket}) => {

    const { id, page } = useParams();
    const [loading, setLoading] = useState(true);
    const [ reload, setReload ] = useState(true);

    console.log(page);
    
    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: id});
            window.scrollTo(0, 0);
            setReload(false);
        }
    }, [setBanner, setReload, banner, reload]);

    const handleDistrictSettings = () => {
        setShowSettings(true);
    }

    const [showSettings, setShowSettings] = useState(false);

    return (
        districts.map(d => (
            d.district.toString() === id && (
                <>
                <Container key={d._id} fluid className='mt-3'>
                    <Row>
                        <Col md={12} className="cursor-pointer d-flex justify-content-center">
                            <Card className="shadow-lg">
                                <Card.Body className="justify-content-center">
                                    <h3 className="card-title text-center">
                                        <div>
                                            District {d.district}
                                        </div>
                                        {(currentUser.isAuthenticated && (currentUser.user.isAdmin > 8 || (currentUser.user.isAdmin.toString() === id && window.location.href.includes('district'))) && currentUser.adminSettings) && (
                                            <Row className='ms-1 mt-1'>
                                                <Col>
                                                    <div className='d-flex justify-content-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={handleDistrictSettings} width="16" height="16" fill="currentColor" className="bi bi-gear-fill mt-1 mb-1 me-3 cursor-pointer" viewBox="0 0 16 16">
                                                            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                                        </svg>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                        <div className='mt-2'>
                                            {d.status === "Inactive" ? (
                                                <img className='status-icon' src={Inactive} />
                                            ) : d.status === "Standby" ? (
                                                <img className='status-icon' src={Standby} />
                                            ) : (
                                                <img className='status-icon' src={Active} />
                                            )}
                                        </div>
                                    </h3>
                                    <div className='text-center'>
                                        <span>Status: </span>
                                        <span>{d.status}</span>
                                    </div>
                                    <div className='text-center'>
                                        <span>Repeater: </span>
                                        <span>{d.lastRepeater} MHz</span>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {posts.map(p => (
                            p.type === `district${d.district}` && (
                                <Col key={p._id} md={12} className="d-flex justify-content-center">
                                    <Card className="shadow-lg">
                                        <Card.Body className="justify-content-center">
                                            <h3 className="card-title text-center">
                                                {p.title}
                                            </h3>
                                            <div dangerouslySetInnerHTML={{__html: p.text}} />
                                            <Editor
                                                dispatchPosts={dispatchPosts}
                                                currentUser={currentUser}
                                                post={p}
                                                socket={socket}
                                            />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        ))}
                    </Row>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings || 
                            currentUser.isAuthenticated && currentUser.user.isAdmin === d.district && currentUser.adminSettings) && (
                                <Card className='text-center justify-content-center cursor-pointer shadow-lg'>
                                    <NewEditor 
                                        dispatchPosts={dispatchPosts}
                                        currentUser={currentUser}
                                        type={`district${d.district}`}
                                        socket={socket}
                                    />
                                </Card>
                            )}
                        </Col>
                    </Row>
                    <DistrictSettings 
                        district={d}
                        showSettings={showSettings}
                        setShowSettings={setShowSettings}
                        currentUser={currentUser}
                        socket={socket}
                    />
                </Container>
                </>
            )
        ))
    );
};

export default DistrictPage;