import React from 'react';
import {Button, Col, Container, Modal, Row} from 'react-bootstrap';
import Moment from 'react-moment';

const SelectedWarning = ({warning, setWarning}) => {

    const handleClose = () => {
        setWarning({data: {}, show: false});
    }
    
    return (
        <Modal className='text-black' show={warning.show} onHide={handleClose}>
            <Modal.Header closeButton>
                    <Container fluid>
                        <div className='d-flex'>
                            <span className='justify-content-start'>{warning.data.event}</span>&nbsp;
                            <span className='justify-content-end'>Expires: <Moment format='MM/DD/yyyy HH:mm:ss'>{warning.data.expires}</Moment></span>
                        </div>
                    </Container>
            </Modal.Header>
            <Modal.Body style={{overflow: "scroll", maxHeight: '70vh'}}>
                <p>{warning.data.headline}</p>
                <p>{warning.data.areaDesc}</p>
                <p>{warning.data.description}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default SelectedWarning;
