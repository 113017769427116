import axios from 'axios';
import React, { useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import ViewDoc from '../Modals/ViewDoc';
import TOS from './TOS';

const Signup = ({setCurrentUser, setShowAuth, setError, socket}) => {

    const [ geoLoc, setGeoLoc ] = useState(true);

    const [ currentDoc, setCurrentDoc ] = useState({
        show: false,
        noBuffer: true,
        data: {
            fileName: "TOS.pdf"
        }
    })

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: null,
        password2: null,
        tos: false,
        ip: null,
        geoData: []
    });

    if(geoLoc){
        axios.get(`https://freeipapi.com/api/json`)
        .then(res => {
            setFormData({...formData, geoData: {...res.data, dateTime: Date.now()}, ip: res.data.ipAddress});
            localStorage.setItem('ip', res.data.ipAddress);
            setGeoLoc(false);
        })
        .catch(err => {
            setGeoLoc(false);
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        socket.emit('create user', formData);
        socket.on('new user', data => {
            localStorage.setItem('auth_token', data.jwt);
            localStorage.setItem('_id', data._id);
            setCurrentUser({user: data, isAuthenticated: true});
            setShowAuth(false);
        });
        socket.on('error', data => {
            setError({...data, show: true});
        });
    }

    const { firstName, lastName, username, email, password, password2, tos } = formData;

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">First Name</label>
                        <input onChange={e => setFormData({...formData, firstName: e.target.value})} type="text" name="firstName" value={firstName} className="form-control shadow" required />
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Last Name</label>
                        <input onChange={e => setFormData({...formData, lastName: e.target.value})} type="text" name="lastName" value={lastName} className="form-control shadow" required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Callsign</label>
                        <input onChange={e => setFormData({...formData, username: e.target.value.toUpperCase()})} type="text" name="username" value={username} className="form-control shadow" required />
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Email address</label>
                        <input onChange={e => setFormData({...formData, email: e.target.value})} type="email" name="email" value={email} className="form-control shadow" required />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Password</label>
                        <input onChange={e => setFormData({...formData, password: e.target.value})} type="password" name="password" value={password} className="form-control shadow" required />
                    </div>
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Confirm Password</label>
                        <input onChange={e => setFormData({...formData, password2: e.target.value})} type="password" name="password2" value={password2} className="form-control shadow" required />
                    </div>
                </div>
                <hr />
                <div>
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label={
                            formData.tos ? "Accepted Terms of Service" : 
                            <button type='button' onClick={() => setCurrentDoc({...currentDoc, show: true})} className='no-button cursor-pointer button-link'>
                                Terms of Service
                            </button>
                        }
                        value={tos}
                        onClick={() => {setFormData({...formData, tos: !tos})} }
                    />
                </div>
                <br />
                <div className="text-center d-flex justify-content-center">
                    <button className="g-recaptcha btn btn-block btn-primary shadow" type='submit'>Submit</button>
                </div>
            </Form>
            <TOS 
                currentDoc={currentDoc}
                setCurrentDoc={setCurrentDoc}
            />
        </Container>
    )
}

export default Signup