import React from 'react';
import { Accordion, Card, Col, Container, ListGroup, Offcanvas, Row } from 'react-bootstrap';
import NWSInfoCard from './NWSInfoCard';
import DistrictSAME from '../../SAMEbyDistrict.json';

const NWSCanvas = ({ showCanvas, setShowCanvas, alerts }) => {

    console.log(alerts)
  const districts = Object.entries(DistrictSAME);

  const checkAlertForDistrict = (alert, districtKeys) => {
    return alert.geocode.SAME.some(code => districtKeys.includes(code));
  };

  return (
    <Offcanvas
      style={{ width: '80%', backgroundColor: 'black', color: 'white' }}
      show={showCanvas}
      onHide={() => setShowCanvas(!showCanvas)}
      enforceFocus={false}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>MRX Alerts</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Container>
          <Row>
            <Col>
              <ListGroup as="ul">
                {districts.map(([districtId, district]) => {
                  const districtKeys = Object.keys(district);
                  const filteredAlerts = alerts.filter(alert => checkAlertForDistrict(alert, districtKeys));

                  if (filteredAlerts.length > 0) {
                    return (
                      <Card as="li" key={districtId}>
                        <Accordion defaultActiveKey={`dist${districtId}`}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header><span>District {districtId}</span></Accordion.Header>
                            <Accordion.Body>
                              <ListGroup as="ul">
                                {filteredAlerts.map(issue => (
                                  <NWSInfoCard key={issue._id} issue={issue} />
                                ))}
                              </ListGroup>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Card>
                    );
                  }
                  return null;
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default NWSCanvas;
