import React from 'react';
import DistrictSAME from '../../SAMEbyDistrict.json';

const NWSAlertBar = ({ setShowCanvas, showCanvas, alerts }) => {
  const districtSAMECodes = Object.values(DistrictSAME).flatMap(Object.keys);

  const filteredAlerts = alerts.filter(alert =>
    alert.geocode.SAME.some(code => districtSAMECodes.includes(code))
  );

    return filteredAlerts.length ? (
        <div className='nws-alert-bar-container'>
            <div
                className='nws-alert-bar'
                variant="danger"
                onClick={e => {
                e.preventDefault();
                setShowCanvas(!showCanvas);
                }}
            >
                <div className='text-center'>
                    <span>
                        There {filteredAlerts.length === 1 ? 'is' : 'are'} {filteredAlerts.length} {filteredAlerts.length === 1 ? 'alert' : 'alerts'} in the MRX WFO
                    </span>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default NWSAlertBar;
