import React, { useState } from 'react';
import {Button, Col, Container, Modal, Row} from 'react-bootstrap';
import Moment from 'react-moment';

const ReportText = ({report, socket}) => {

    const [ show, setShow ] = useState(false);
    
    return (
        <>
            <div className='text-center'>
            <button className='no-button'>
                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setShow(true)} width="22" height="22" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                    <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                </svg>
            </button>
            </div>
            <Modal className='text-black' show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{report.callsign}'s Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <span style={{fontWeight: 'bold'}}>Full Report:&nbsp;</span>
                                <span>{report.text}</span>
                            </Col>
                        </Row>
                        {report.tornado && (
                            <Row>
                                <Col>
                                    <span style={{fontWeight: 'bold'}}>Tornado:&nbsp;</span>
                                    <span>Observed</span>
                                </Col>
                            </Row>
                        )}
                        {report.funnel && (
                            <Row>
                                <Col>
                                    <span style={{fontWeight: 'bold'}}>Funnel:&nbsp;</span>
                                    <span>Observed</span>
                                </Col>
                            </Row>
                        )}
                        {report.flood && (
                            <Row>
                                <Col>
                                    <span style={{fontWeight: 'bold'}}>Flood:&nbsp;</span>
                                    <span>Observed</span>
                                </Col>
                            </Row>
                        )}
                        {report.wind && (
                            <Row>
                                <Col>
                                    <span style={{fontWeight: 'bold'}}>Wind:&nbsp;</span>
                                    <span>{report.wind} MPH</span>
                                </Col>
                            </Row>
                        )}
                        {report.hail && (
                            <Row>
                                <Col>
                                    <span style={{fontWeight: 'bold'}}>Hail:&nbsp;</span>
                                    <span>{report.hail}"</span>
                                </Col>
                            </Row>
                        )}
                        {report.rain && (
                            <Row>
                                <Col>
                                    <span style={{fontWeight: 'bold'}}>Rain:&nbsp;</span>
                                    <span>{report.rain}" /Hr</span>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default ReportText;
