
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import TableContainerReverseSort from '../Table/TableContainerReverseSort';
import ReportText from '../Modals/ReportText';

const ViewReports = ({viewReports, setViewReports, socket}) => {

    const [ loading, setLoading ] = useState(true);

    const [{ reports }, dispatchReports] = useReducer((state, action) => {
        if (action.type === 'NEW_REPORT') {
            return {
                ...state,
                reports: [...state.reports.filter(report => report._id !== action.data._id), action.data]
            }
        } else if(action.type === "LOAD_REPORTS"){
            state.reports = action.data;
            return state;
        }
        return state
    }, { reports: [] });


    if(loading){
        let payload = {
            ip: localStorage.getItem('ip')
        }
        socket.emit('load reports', payload);
        socket.on('reports loaded', data => {
            dispatchReports({data, type: 'LOAD_REPORTS'});
            setLoading(false);
        });
    }

    useEffect(() => {
        socket.on('reported', data => {
            dispatchReports({data, type: "NEW_REPORT"});
        });
    }, [socket]);

    const columns = useMemo(() => [
        {
            Header: "ID",
            accessor: "reportId",
            id: "reportId"
        },
        {
            Header: "Dist.",
            accessor: "reportId",
            id: "district",
            Cell: e => e.cell.value.split("D")[0]
        },
        {
            Header: "Callsign",
            accessor: "callsign",
        },
        {
            Header: "Time",
            accessor: "timestamp",
            Cell: e => {
                return(
                    <Moment format='YYYY-MM-DD HH:mm:ss'>{e.value}</Moment>
                )
            }
        },
        {
            Header: "Location",
            accessor: "location",
        },
        {
            Header: "Rain",
            accessor: "rain",
            Cell: e => e.value !== "" ? e.value + '" /Hr' : ""
        },
        {
            Header: "Wind",
            accessor: "wind",
            Cell: e => e.value !== "" ? e.value + ' MPH' : ""
        },
        {
            Header: "Hail",
            accessor: "hail",
            Cell: e => e.value !== "" ? e.value + '"' : ""
        },
        {
            Header: "Flood",
            accessor: "flood",
            Cell: e => e.value === true ? "Yes" : ""
        },
        {
            Header: "Funnel",
            accessor: "funnel",
            Cell: e => e.value === true ? "Yes" : ""
        },
        {
            Header: "Tornado",
            accessor: "tornado",
            Cell: e => e.value === true ? "Yes" : ""
        },
        {
            Header: 'Report',
            accessor: 'text',
            disableFilters: true,
            Cell: e => <ReportText report={e.row.original} />
        },
    ], []);
    
    return (
        <Modal
            fullscreen={true}
            show={viewReports}
            onHide={() => setViewReports(false)}
            dialogClassName="net-modal"
            className='text-black'
            aria-labelledby="example-custom-modal-styling-title"
        >
        <Modal.Header className='modal-header' closeButton>
            <Row>
                <Col className='d-flex justify-content-end'>
                    <Modal.Title>Active Reporting</Modal.Title>
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body className='modal-body'>
            <Row>
                <TableContainerReverseSort 
                    data={reports}
                    columns={columns}
                />
            </Row>
        </Modal.Body>
      </Modal>
    );
};

export default ViewReports