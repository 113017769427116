import React, { useEffect, useReducer, useState, useCallback, useMemo } from 'react';
import TableContainer from '../Table/TableContainer';
import Moment from 'react-moment';
import SelectedWarning from '../Modals/SelectedWarning';
import SAMEbyDistrict from '../../SAMEbyDistrict.json';

const NWSIndex = ({ socket, districtNum }) => {
  const [loading, setLoading] = useState(true);
  const [selectedWarning, setSelectedWarning] = useState({
    show: false,
    data: {}
  });

  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case 'NEW_ALERT':
        return {
          ...state,
          alerts: [...state.alerts, { ...action.data }],
        };
      case 'LOAD_ALERTS':
        return {
          ...state,
          alerts: action.data,
        };
      case 'REMOVE_ALERT':
        return {
          ...state,
          alerts: state.alerts.filter(alert => alert._id !== action.data._id),
        };
      default:
        return state;
    }
  }, []);

  const [{ alerts }, dispatch] = useReducer(reducer, { alerts: [] });

  useEffect(() => {
    if (loading) {
      socket.emit('load alerts');
      socket.on('alerts loaded', data => {
        dispatch({ type: 'LOAD_ALERTS', data });
        setLoading(false);
      });

      return () => {
        socket.off('alerts loaded');
      };
    }
  }, [loading, socket]);

  useEffect(() => {
    const handleNewAlert = data => {
      dispatch({ type: 'NEW_ALERT', data });
    };

    const handleRemoveAlert = data => {
      dispatch({ type: 'REMOVE_ALERT', data });
    };

    socket.on('nws update', handleNewAlert);
    socket.on('remove alert', handleRemoveAlert);

    return () => {
      socket.off('nws update', handleNewAlert);
      socket.off('remove alert', handleRemoveAlert);
    };
  }, [socket]);

  const columns = useMemo(() => [
    {
      Header: 'Event',
      accessor: 'event',
      disableFilters: false,
      descending: true,
    },
    {
      Header: 'Area',
      accessor: 'areaDesc',
      disableFilters: false,
      descending: true,
    },
    {
      Header: 'Effective',
      accessor: 'effective',
      disableFilters: false,
      descending: true,
      Cell: e => <Moment format='MM/DD/yyyy HH:mm'>{e.value}</Moment>
    },
    {
      Header: 'Expiration',
      accessor: 'expires',
      disableFilters: false,
      descending: true,
      Cell: e => <Moment format='MM/DD/yyyy HH:mm'>{e.value}</Moment>
    }
  ], []);

  const handleRowClick = e => {
    const { original } = e;
    if (original) {
      setSelectedWarning({ show: true, data: original });
    }
  };

  const getSameCodes = useCallback((groups) => {
    const data = SAMEbyDistrict;
    let result = [];
    
    groups.forEach(group => {
      if (data.hasOwnProperty(group)) {
        result = result.concat(Object.keys(data[group]));
      }
    });
    
    return result;
  }, []);

  const filteredAlerts = useMemo(() => {
    const sameCodes = getSameCodes([districtNum]);
    
    return alerts.filter(a => {
      return Array.isArray(a.geocode.SAME) && sameCodes.some(code => a.geocode.SAME.includes(code));
    });
  }, [alerts, districtNum, getSameCodes]);

  return (
    (!loading && alerts.length > 0) ? (
      <>
        {filteredAlerts.length > 0 ? (
          <div style={{ overflow: "scroll", maxHeight: '70vh' }}>
            <TableContainer
              data={filteredAlerts}
              columns={columns}
              clickFunction={handleRowClick}
            />
          </div>
        ) : (
          "No active alerts"
        )}
        <SelectedWarning 
          warning={selectedWarning}
          setWarning={setSelectedWarning}
        />
      </>
    ) : (
      "No active alerts"
    )
  );
};

export default NWSIndex;
