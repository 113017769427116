import React, { useState } from 'react';
import {Row, Container, Form, Col, FloatingLabel, Button} from 'react-bootstrap';
import Select from 'react-select';

const Settings = ({currentUser, setCurrentUser, socket}) => {

    const [ updatedUser, setUpdatedUser ] = useState(currentUser.user);

    const handleSubmit = e => {
        e.preventDefault();
        let payload = {
            user: updatedUser,
            requestor: currentUser.user,
            jwt: localStorage.getItem('auth_token'),
            ip: localStorage.getItem('ip')
        }
        socket.emit('update user', payload);
        socket.on('user updated', data => {
            setCurrentUser({...currentUser, user: data});
        });
    }

    const countyOptions = [
        { value: 'Anderson, TN', label: 'Anderson, TN' },
        { value: 'Blount, TN' , label: 'Blount, TN' },
        { value: 'Bradley, TN' , label: 'Bradley, TN' },
        { value: 'Campbell, TN', label: 'Campbell, TN' },
        { value: 'Carter, TN', label: 'Carter, TN' },
        { value: 'Cherokee, NC', label: 'Cherokee, NC' },
        { value: 'Claiborne, TN', label: 'Claiborne, TN' },
        { value: 'Cocke, TN', label: 'Cocke, TN' },
        { value: 'Grangier, TN', label: 'Grangier, TN' },
        { value: 'Greene, TN', label: 'Greene, TN' },
        { value: 'Hancock, TN' , label: 'Hancock, TN' },
        { value: 'Hamblen, TN', label: 'Hamblen, TN' },
        { value: 'Hamilton, TN', label: 'Hamilton, TN' },
        { value: 'Hawkins, TN', label: 'Hawkins, TN' },
        { value: 'Jefferson, TN', label: 'Jefferson, TN' },
        { value: 'Johnson, TN', label: 'Johnson, TN' },
        { value: 'Knox, TN', label: 'Knox, TN' },
        { value: 'Loudon, TN', label: 'Loudon, TN' },
        { value: 'Marion, TN', label: 'Marion, TN' },
        { value: 'McMinn, TN' , label: 'McMinn, TN' },
        { value: 'Meigs, TN' , label: 'Meigs, TN' },
        { value: 'Monroe, TN', label: 'Monroe, TN' },
        { value: 'Morgan, TN', label: 'Morgan, TN' },
        { value: 'Polk, TN' , label: 'Polk, TN' },
        { value: 'Rhea, TN' , label: 'Rhea, TN' },
        { value: 'Roane, TN' , label: 'Roane, TN' },
        { value: 'Scott, TN', label: 'Scott, TN' },
        { value: 'Sequachie, TN', label: 'Sequachie, TN' },
        { value: 'Sevier, TN', label: 'Sevier, TN' },
        { value: 'Sullivan, TN', label: 'Sullivan, TN' },
        { value: 'Unicoi, TN', label: 'Unicoi, TN' },
        { value: 'Union, TN', label: 'Union, TN' },
        { value: 'Washington, TN', label: 'Washington, TN' },
    ]

   return (
        <Container className="mt-3">
            <Row className="mt-3 text-center">
                <h3>{currentUser.user.username}'s Settings</h3>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col className='me-5'>
                        <Row className='text-center'>
                            <h4>Information</h4>
                        </Row>
                        <Row>
                            <FloatingLabel label="&nbsp;&nbsp;&nbsp;Callsign" className="mb-3 text-black">
                                <Form.Control 
                                    type="text" 
                                    value={updatedUser.username} 
                                    onChange={e => setUpdatedUser({...updatedUser, username: e.target.value.toUpperCase()}) }
                                />
                            </FloatingLabel>
                            <FloatingLabel label="&nbsp;&nbsp;&nbsp;Email" className="mb-3 text-black">
                                <Form.Control 
                                    type="email" 
                                    value={updatedUser.email} 
                                    onChange={e => setUpdatedUser({...updatedUser, email: e.target.value}) }
                                />
                            </FloatingLabel>
                            <FloatingLabel className='text-black mb-3' label="&nbsp;&nbsp;&nbsp;First Name">
                                <Form.Control 
                                    type="text" 
                                    value={updatedUser.firstName} 
                                    onChange={e => setUpdatedUser({...updatedUser, firstName: e.target.value}) }
                                />
                            </FloatingLabel>
                            <FloatingLabel className='text-black mb-3' label="&nbsp;&nbsp;&nbsp;Last Name">
                                <Form.Control 
                                    type="text" 
                                    value={updatedUser.lastName} 
                                    onChange={e => setUpdatedUser({...updatedUser, lastName: e.target.value}) }
                                />
                            </FloatingLabel>
                        </Row>
                    </Col>
                    <Col className='ms-5'>
                        <Row className='text-center'>
                            <h4>Alerts</h4>
                        </Row>
                        <Row className='ms-1'>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label="Alerts"
                                defaultChecked={updatedUser.alerts}
                                onChange={e => setUpdatedUser({...updatedUser, alerts: !updatedUser.alerts}) }
                            />
                        </Row>
                        {updatedUser.alerts && (
                            <Row>
                                <Select 
                                    className='text-black mt-3'
                                    isMulti
                                    defaultValue={updatedUser.counties}
                                    closeMenuOnSelect={false}
                                    onChange={e => setUpdatedUser({...updatedUser, counties: e})}
                                    options={countyOptions}
                                />
                            </Row>
                        )}
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col>
                        <Button onClick={handleSubmit} className='btn-block'>Update Settings</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
   )
};

export default Settings;