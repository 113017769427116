import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { io } from 'socket.io-client';

const DecisionCheck = ({ check, setCheck, title, body, socketName, id, socket }) => {
  // Set the endpoint of the socket connection to the URL specified in the environment variables
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;

  // This function handles closing the modal and sets the check state to false
  const handleClose = () => {
    setCheck({ show: false, proceed: false });
  };

  // This function handles deleting the item and emits a socket event with the payload
  const handleDelete = () => {
    // Create a socket connection using the endpoint and specify the transport type as 'websocket'
    const socket = io(ENDPOINT, { transports: ['websocket'] });
    // Create a payload object containing the user ID and the ID of the item to be deleted
    const payload = {
      userId: localStorage.getItem('id'),
      id,
    };
    // Emit the socket event with the payload object and close the modal
    socket.emit(socketName, payload);
    handleClose();
  };
  
  return (
    // Render the Modal component with the specified props
    <Modal className='text-black' show={check.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setCheck({ ...check, show: false })}>
          No
        </Button>
        <Button variant='danger' onClick={handleDelete}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DecisionCheck;