import React, { useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import authorImage from '../images/author.webp';
import '../Author.css'; // Import the CSS file

const Author = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <Container className={`text-center mt-5 pt-5 mb-5 pb-5 ${fadeIn ? 'spin-fade-grow' : ''}`}>
      <h1>Created By:</h1>
      <Image src={authorImage} />
      <p>Jordan Webb, NA4WX</p>
      <Button variant='secondary'><a className='nav-link nav-link-hover' href='https://na4wx.com' target='__blank'>View Website</a></Button>
    </Container>
  );
};

export default Author;
