import React, { useState } from "react";

const SAME = ({issue}) => {

  const [ codes ] = useState({
    "047007": "Bledsoe",
    "047115": "Marion",
    "047153": "Sequatchie",
    "047065": "Hamilton",
    "047121": "Meigs",
    "047143": "Rhea",
    "047011": "Bradley",
    "047107": "McMinn",
    "047139": "Polk",
    "047129": "Morgan",
    "047123": "Monroe",
    "047151": "Scott",
    "047001": "Anderson",
    "047009": "Blount",
    "047013": "Campbell",
    "047019": "Carter",
    "047025": "Claiborne",
    "047027": "Clay",
    "047029": "Cocke",
    "047057": "Grainger",
    "047059": "Greene",
    "047063": "Hamblen",
    "047067": "Hancock",
    "047073": "Hawkins",
    "047091": "Johnson",
    "047093": "Knox",
    "047105": "Loudon",
    "047145": "Roane",
    "047155": "Sevier",
    "047163": "Sullivan",
    "047173": "Union",
    "047179": "Washington"
  });

  return (
    issue.geocode.SAME.map(sameCode => (
      codes[sameCode] && (
        <span>{codes[sameCode]}, </span>
      )
    ))
  )
}

export default SAME;