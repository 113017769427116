import React from "react";
import { Col, Row } from "react-bootstrap";

const TablePagination = ({
    pageOptions,
    pageCount,
    pageIndex,
    pageSize,
    canPreviousPage,
    previousPage,
    gotoPage,
    nextPage,
    canNextPage,
    onChangeInInput,
    onChangeInSelect
}) => {

    return (
        <div className="pagination">
            <Row className="pt-3" style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
                <Col md={2}>
                    <span style={{cursor: "pointer"}} onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}>{"<<"}&nbsp;</span>
                    <span 
                        style={{cursor: "pointer"}} 
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                    >   {"<"}
                    </span>
                </Col>
                <Col md={3} style={{ marginTop: 7 }}>
                    <p style={{marginTop: -6}}>
                        Page{" "}
                        <strong>
                            {pageIndex + 1}{" "}
                        </strong>
                        of {" "}
                        <strong>
                            {pageOptions.length}
                        </strong>
                    </p>
                </Col>
                <Col md={2}>
                    <input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                    />
                </Col>
                <Col md={2}>
                    <select type="select" value={pageSize} onChange={onChangeInSelect}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                        ))}
                    </select>
                </Col>
                <Col md={3}>
                    <span style={{cursor: "pointer"}} onClick={nextPage} disabled={!canNextPage}>{">"}&nbsp;</span>
                    <span 
                        style={{cursor: "pointer"}} 
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >   {">>"}
                    </span>
                </Col>
            </Row>
        </div>
    )
}

export default TablePagination