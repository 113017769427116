import React, { useEffect, useMemo, useReducer, useState } from 'react';
import Moment from 'react-moment';
import TableContainerReverseSort from '../Table/TableContainerReverseSort';
import ReportText from '../Modals/ReportText';

const Reports = ({ netId, socket }) => {

  const [loading, setLoading] = useState(true);

  const [{ reports }, dispatchReports] = useReducer((state, action) => {
    switch (action.type) {
      case 'NEW_REPORT':
        return {
          ...state,
          reports: [
            ...state.reports.filter(report => report._id !== action.data._id),
            action.data
          ]
        };
      case 'LOAD_REPORTS':
        return {
          ...state,
          reports: action.data.reports
        };
      default:
        return state;
    }
  }, { reports: [] });

  useEffect(() => {
    socket.on('reported', data => {
        dispatchReports({ data, type: 'NEW_REPORT' });
    });
  }, [socket]);

  useEffect(() => {
    if (loading) {
      let payload = {
        netId,
        ip: localStorage.getItem('ip')
      };
      socket.emit('load checkins', payload);
      socket.on('checkins loaded', data => {
        dispatchReports({ data, type: 'LOAD_REPORTS' });
        setLoading(false);
      });
    }
  }, [socket, netId]);

  const columns = useMemo(() => [
    {
      Header: 'Report ID',
      accessor: 'reportId',
      disableFilters: true,
      descending: true
    },
    {
      Header: 'Callsign',
      accessor: 'callsign',
      disableFilters: true,
      descending: true
    },
    {
      Header: 'Time',
      accessor: 'timestamp',
      disableFilters: true,
      Cell: e => {
        return (
          <Moment format='HH:mm'>{e.value}</Moment>
        )
      }
    },
    {
      Header: 'Location',
      accessor: 'location',
      disableFilters: true,
    },
    {
      Header: 'Rain',
      accessor: 'rain',
      disableFilters: true,
      Cell: e => e.value !== '' ? e.value + '" /Hr' : ''
    },
    {
      Header: 'Wind',
      accessor: 'wind',
      disableFilters: true,
      Cell: e => e.value !== '' ? e.value + ' MPH' : ''
    },
    {
      Header: 'Hail',
      accessor: 'hail',
      disableFilters: true,
      Cell: e => e.value !== '' ? e.value + '"' : ''
    },
    {
      Header: 'Flood',
      accessor: 'flood',
      disableFilters: true,
      Cell: e => e.value === true ? 'Yes' : ''
    },
    {
      Header: 'Funnel',
      accessor: 'funnel',
      disableFilters: true,
      Cell: e => e.value === true ? 'Yes' : ''
    },
    {
      Header: 'Tornado',
      accessor: 'tornado',
      disableFilters: true,
      Cell: e => e.value === true ? 'Yes' : ''
    },
    {
        Header: 'Report',
        accessor: 'text',
        disableFilters: true,
        Cell: e => <ReportText report={e.row.original} />
    },
  ]);
    
    return (
        !loading && (
            <TableContainerReverseSort columns={columns} data={reports} showPagination={false} />
        )
    );
};

export default Reports