import React, { useState } from 'react';
import { Button, Col, FloatingLabel, Modal, Row, Form } from 'react-bootstrap';
import NewImage from './NewImage';
import NewPage from './NewPage';

const DistrictSettings = ({district, showSettings, setShowSettings, currentUser, socket}) => {

    const handleClose = () => {
        setShowSettings(false);
    }

    const [distClone, setDistClone] = useState(district);

    const handleSubmit = () => {
        let authToken = localStorage.getItem('auth_token');
        let userId = localStorage.getItem('_id') ? localStorage.getItem('_id') : "";
        socket.emit('district settings', {_id: userId, jwt: authToken, data: distClone});
        handleClose();
    }
    
    return (
        <Modal className='text-black' show={showSettings} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>District {district.district} Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Discord Webhook"
                            className="mb-3 text-black"
                        >
                            <Form.Control 
                                type="text" 
                                onChange={e => setDistClone({...distClone, discordWebhook: e.target.value})} 
                                value={distClone.discordWebhook} 
                                placeholder="https://webhook.discord.com/"
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NewImage 
                            currentUser={currentUser}
                            showButton={true}
                            socket={socket}
                        />
                    </Col>
                    <Col>
                        <NewPage 
                            district={district.district}
                            socket={socket}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button onClick={handleSubmit} variant="primary">
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default DistrictSettings;
