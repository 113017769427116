import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Loading from '../loading';

const TOS = ({ currentDoc, setCurrentDoc }) => {
    
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      fullscreen={true}
      show={currentDoc.show}
      onHide={() => setCurrentDoc({ ...currentDoc, show: false })}
      dialogClassName="net-modal"
      className='text-black'
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header className='modal-header' closeButton>
        Doc
      </Modal.Header>
      <Modal.Body>
        {(!loading && currentDoc.show) ? (
          <div>
            <iframe
              width="100%"
              height="700"
              frameBorder="0"
              src={`https://docs.google.com/gview?url=${
                `${process.env.REACT_APP_SOCKET_URL}/file/TOS.pdf`
              }&embedded=true`}
              title="Document Viewer"
            >
            </iframe>
          </div>
        ) : (
          <Loading />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TOS;
