import React from "react";
import { Card } from "react-bootstrap";
import SAME from "./SAMEcodes";

const NWSInfoCard = ({issue}) => {

  return (
    <Card style={{backgroundColor: "white"}}
        key={issue.data} as="li"
    >
        <Card.Header 
            className='text-center' 
            style={{backgroundColor: issue.event.includes("Warning") ? "red" : "yellow"}}
        >
            {issue.event}
        </Card.Header>
        <Card.Body>
            <p className='text-center'>Issued: {`${issue.effective.split("T")[0]} at 
                ${issue.effective.split("T")[1].split('-')[0]}`}
            </p>
            <p className='text-center'>Expires: {`${issue.expires.split("T")[0]} at 
                ${issue.expires.split("T")[1].split('-')[0]}`}
            </p>
            <hr />
            <p>
                The National Weather Service in {issue.senderName.split(" ")[1]} 
                &nbsp;has issued a {issue.event} for the following counties: <SAME issue={issue}/>
            </p>
            <p>
                This includes the locations:&nbsp;{issue.areaDesc}
            </p>
            <hr />
            <h5>Full Description</h5>
            <p>
                {issue.description.includes('http') ? (
                    <>
                        {issue.description.split('http')[0]}
                        <a 
                            target="_blank" 
                            rel="noreferrer" 
                            href={
                                "http" + issue.description.split('http')[1].split(" ")[0]
                            }
                        >
                            More Info
                        </a>
                    </>
                ) : (
                    issue.description
                )}
            </p>
        </Card.Body>
    </Card>
  )
}

export default NWSInfoCard;