
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TableContainer from '../Table/TableContainer';
import ViewReports from './ViewReports';

const Nets = ({districts, setBanner, banner, socket}) => {

    const nav = useNavigate();
    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if(reload){
            setBanner({...banner, loading: false, id: 0});
            window.scrollTo(0, 0);
            setReload(false);
        }
    }, [reload, setBanner, banner, setReload]);

    const data = districts.map(d => ({
        district: d.district,
        status: "Active",
        dateTime: "01/10/2023 10:15 PM",
    }));

    const handleNav = row => {
        nav(`${row.cells[0].value}`);
    }

    const [ viewReports, setViewReports ] = useState(false);

    const columns = useMemo(() => [
        {
            Header: "District",
            accessor: "district",
            disableFilters: true
        },
        {
            Header: "Status",
            accessor: "status",
            disableFilters: true
        },
        {
            Header: "Last Status",
            accessor: "dateTime",
            disableFilters: true
        },
        {
            Header: "View",
            accessor: "view",
            disableFilters: true,
            Cell: ({ row }) => {
                return <button className='no-button button-link' onClick={e => handleNav(row)}>District {row.cells[0].value} Nets</button>;
            },
        },
    ], []);
    
    return (
        <Container fluid className='mt-2'>
            <Container fluid className='mb-2'>
                <Button className='btn-block btn-secondary' onClick={e => setViewReports(!viewReports)}>View Live Reports</Button>
            </Container>
            <ViewReports 
                viewReports={viewReports}
                setViewReports={setViewReports}
                socket={socket}
            />
            <TableContainer columns={columns} data={data} />
        </Container>
    );
};

export default Nets