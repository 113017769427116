
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import Articles from '../Articles';
import Loading from '../loading';
import Districts from './Districts';
import NewImage from '../Modals/NewImage';
import CreatableSelect from 'react-select';
import Select from 'react-select';

const DistStat = ({ show, setShow, district, socket}) => {

    const [ distClone, setDistClone ] = useState(district);

    const [ showWebhook, setShowWebhook ] = useState(false);

    const updateStatus = e => {
        let payload = {
            ...distClone,
            type: 'update'
        }
        socket.emit('districts', payload);
        setShow(false);
    }

    const handleClose = () => {
        setShow(!show);
    }

    return (
        <Modal show={show}>
                <Modal.Header closeButton onHide={handleClose}>
                <Modal.Title>District {distClone.district} Status</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className='ms-1 mb-3'>
                        <Col>
                            <Select 
                                className='text-black'
                                defaultValue={{label: distClone.status, value: distClone.status}}
                                onChange={ e => setDistClone({...distClone, status: e.value}) }
                                options={[
                                    {label: 'Active', value: 'Active'},
                                    {label: 'Standby', value: 'Standby'},
                                    {label: 'Inactive', value: 'Inactive'},
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row className='ms-1 mb-3'>
                        <Col>
                            <FloatingLabel className='text-black mb-3' label="Repeater">
                                <Form.Control 
                                    type="text" 
                                    value={distClone.lastRepeater} 
                                    onChange={ e => setDistClone({...distClone, lastRepeater: e.target.value}) }
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='ms-1 mb-3'>
                        <Col>
                            {!showWebhook ? (
                                <Button onClick={() => setShowWebhook(!showWebhook)}>Edit Webhook</Button>
                            ) : (
                                <FloatingLabel className='text-black mb-3' label="Discord Webhook">
                                    <Form.Control 
                                        type="text" 
                                        value={distClone.discordWebhook} 
                                        onChange={ e => setDistClone({...distClone, discordWebhook: e.target.value}) }
                                    />
                                </FloatingLabel>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                <Button variant="warning" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={updateStatus}>Save changes</Button>
                </Modal.Footer>
        </Modal>
    );
    
};

export default DistStat