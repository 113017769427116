import React from 'react';
import {Button, Card, Col, Modal, Row} from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const NewNet = ({newNet, setNewNet, district, handleNewNet, socket}) => {

    const handleClose = () => {
        setNewNet({
            ...newNet,
            show: false
        });
    }
    
    return (
        <Row style={{marginTop: "-10px"}}>
            <Col className='d-flex justify-content-center'>
                <Card className='text-center justify-content-center cursor-pointer shadow-lg'>
                    <Card.Body onClick={e => setNewNet({...newNet, show: true})}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                        </svg>
                    </Card.Body>
                </Card>
                <Modal show={newNet.show} size="lg" onHide={handleClose} className="text-black">
                    <Modal.Header closeButton>
                    <Modal.Title>Launch District {district.district} Net</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-black'>
                        <CreatableSelect 
                            creatable
                            isClearable
                            placeholder="Repeater"
                            onChange={e => {console.log(e.value); setNewNet({...newNet, repeater: e.value})}}
                            options={[
                                { value: newNet.repeater, label: newNet.repeater },
                                district.repeaters.map(r => [
                                    { value: r.repeater, label: r.repeater }
                                ])
                            ]}
                        />
                        <Select isClearable
                            className='mt-3'
                            placeholder="Status"
                            onChange={e => setNewNet({...newNet, status: e.value})}
                            options={[
                                {value: "Active", label: "Active"},
                                {value: "Standby", label: "Standby"},
                                {value: "Inactive", label: "Inactive"}
                            ]}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={handleNewNet}>
                            Launch Net
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Col>
        </Row>
    )
};

export default NewNet;
