
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Card, Col, Container, FloatingLabel, Form, Modal, Row, Alert } from 'react-bootstrap';
import Reports from './Reports';
import Moment from 'react-moment';
import TableContainerReverseSort from '../Table/TableContainerReverseSort';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const ViewNet = ({distNet, setDistNet, currentUser, district, socket}) => {

    const [ formData, setFormData ] = useState({
        callsign: "",
        name: "",
        report: "",
    });

    const [ loading, setLoading ] = useState(true);

    const [ showKeywords, setShowKeywords ] = useState(false);

    const [{ checkins }, dispatchCheckins] = useReducer((state, action) => {
        if (action.type === 'NEW_CHECKIN') {
            return {
                ...state,
                checkins: [...state.checkins.filter(checkins => checkins._id !== action.data._id), action.data]
            }
        } else if(action.type === 'NEW_REPORT'){
            return {
                ...state,
                reports: [...state.reports.filter(report => report._id !== action.data._id), action.data],
            }
        } else if(action.type === "LOAD_CHECKINS"){
            state.checkins = action.data.checkins;
            return state;
        }
        return state
    }, { checkins: [], reports: [] });

    useEffect(() => {
        if(loading){
            let payload = { 
                netId: distNet.data,
                ip: localStorage.getItem('ip')
            };
            socket.emit('load checkins', payload);
            socket.on('checkins loaded', data => {
                dispatchCheckins({data, type: 'LOAD_CHECKINS'});
                setLoading(false);
            });
        }

        socket.on('checked in', data => {
            dispatchCheckins({data, type: "NEW_CHECKIN"});
        });

        socket.on('update checkin', data => {
            dispatchCheckins({data, type: "UPDATE_CHECKIN"});
        });

        const listener = event => {
            if (event.code === "Tab") {
                checkins.forEach(c => {
                    if(c.callsign === event.target.value){
                        setFormData({
                            ...formData, 
                            callsign: event.target.value, 
                            name: c.name, 
                            checkinId: c._id
                        });
                        setNameFilled(true);
                    }  else {
                        setNameFilled(false);
                    }
                });
            } else if(formData.callsign !== "" && event.code === "Enter" && event.target.name === "report") {
                event.preventDefault();
                handleSubmit();
            }
        };

        document.addEventListener("keydown", listener);

        return () => {
            document.removeEventListener("keydown", listener);
        };
      }, [socket, formData, checkins]);

    const [columns] = useState([
        {
            Header: "Callsign",
            accessor: "callsign",
            Cell: row => row.cell.value.toUpperCase(),
            maxWidth: 100,
            width: 100,
        },
        {
            Header: "Name",
            accessor: "name",
        },
        {
            Header: "Location",
            accessor: "location",
        },
        {
            Header: "Time",
            accessor: "timestamp",
            Cell: e => <Moment format='HH:mm'>{e.cell.value}</Moment>,
        },
    ]);

    const [ nameFilled, setNameFilled ] = useState(false);

    const [ alrt, setAlrt ] = useState({
        show: false,
        message: null
    });

    const handleSubmit = e => {
        let payload = { 
            userId: currentUser.user._id, 
            jwt: currentUser.user.jwt, 
            netId: distNet.data._id,
            formData
        };
        formData.report = " " + formData.report;
        if(formData.report.includes(" ha") || formData.report.includes(" hail") || 
            formData.report.includes(" wnd") || formData.report.includes(" wind") ||
            formData.report.includes(" rn") || formData.report.includes(" rain") ||
            formData.report.includes(" tor") || formData.report.includes(" tornado") ||
            formData.report.includes(" fnl") || formData.report.includes(" funnel") ||
            formData.report.includes(" fl") || formData.report.includes(" flood") ||
            formData.report.includes(" dmg")|| formData.report.includes(" damage")
        ){
            if(formData.report.includes('loc ') || formData.report.includes("location ")){
                socket.emit('new report', payload);
            } else {
                setAlrt({show: true, message: "Location is Required"});
                setTimeout(() => {
                    setAlrt({show: false});
                }, 3000);
            }
        } else {
            socket.emit('new checkin', payload);
        }
        
        setFormData({
            callsign: "",
            name: "",
            report: "",
        });
        setNameFilled(false);
    }
    
    const updateStatus = e => {
        setDistNet({...distNet, data: {...distNet.data, status: e.value}});
        let payload = {
            user: currentUser,
            net: {...distNet, data: {...distNet.data, status: e.value}},
            ip: localStorage.getItem('ip')
        }
        console.log('hit')
        socket.emit('update net', payload);
    }

    const updateRepeater = r => {
        setDistNet({...distNet, data: {...distNet.data, repeater: r.value}});
        let payload = {
            user: currentUser,
            net: {...distNet, data: {...distNet.data, repeater: r.value}},
            ip: localStorage.getItem('ip')
        }
        socket.emit('update net', payload);
    }
    
    return (
        <Modal
            fullscreen={true}
            show={distNet.show}
            onHide={() => setDistNet({...distNet, show: false})}
            dialogClassName="net-modal"
            className='text-black'
            aria-labelledby="example-custom-modal-styling-title"
        >
        <Modal.Header className='modal-header' closeButton>
            <Row>
                <Col className='d-flex justify-content-end'>
                    <span>
                        <Modal.Title id="example-custom-modal-styling-title">
                            District {distNet.data.type} Net {distNet.data.status} {distNet.data.repeater && "on " + distNet.data.repeater}
                        </Modal.Title>
                    </span>
                    {(currentUser.isNcs == distNet.type || currentUser.isAdmin > 9) && (
                        <>
                            {!showKeywords && (
                                <span className='ms-5 mt-1'>
                                    <Button 
                                        size="sm" 
                                        variant="secondary"
                                        onClick={e => setShowKeywords(true)}
                                    >
                                        Keywords
                                    </Button>
                                </span>
                            )}
                            <Select isClearable
                                className='ms-5 text-black'
                                placeholder="Status"
                                defaultValue={{value: distNet.data.status, label: distNet.data.status}}
                                onChange={e => updateStatus(e)}
                                options={[
                                    {value: "Active", label: "Active"},
                                    {value: "Standby", label: "Standby"},
                                    {value: "Inactive", label: "Inactive"}
                                ]}
                            />
                            <CreatableSelect
                                className='ms-5 text-black'
                                creatable
                                isClearable
                                placeholder="Repeater"
                                onChange={r => {console.log(r); updateRepeater(r)}}
                                defaultValue={district.lastRepeater}
                                options={
                                    district.repeaters.map(r => (
                                        { value: r.repeater, label: r.repeater }
                                    ))
                                }
                            />
                        </>
                    )}
                </Col>
            </Row>
        </Modal.Header>
        <Modal.Body className='modal-body'>
            {(currentUser.isNcs == distNet.type || currentUser.isAdmin > 9) && (
                <div>
                    <div className='d-flex justify-content-center'>
                        {showKeywords && (
                            <Card className='legend-card'>
                                <Card.Body className='text-black' style={{paddingBottom: "0px"}}> 
                                    <Card.Header className='mb-3 d-flex justify-content-between align-items-center'>
                                            <h5 className='text-white'>Keywords</h5>
                                            <Button size='sm' variant="secondary" onClick={e => setShowKeywords(false)}>Hide</Button>
                                    </Card.Header>
                                    <Row>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>ha</strong></span> Hail</p>
                                        </Col>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>wnd</strong></span> Wind</p>
                                        </Col>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>rn</strong></span> Rain</p>
                                        </Col>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>loc</strong></span> Location</p>
                                        </Col>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>tor</strong></span> Tornado</p>
                                        </Col>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>fnl</strong></span> Funnel</p>
                                        </Col>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>fl</strong></span> Flood</p>
                                        </Col>
                                        <Col>
                                            <p className='key-item'><span className='key-word'><strong>dmg</strong></span> Damage</p>
                                        </Col>
                                    </Row>
                                    <Row className='text-white'>
                                        <p className='text-center'>Please end all items with a ' . ' (period).</p>
                                    </Row>
                                    <Row className='text-white'>
                                        <h4>Example:</h4>
                                        <br/>
                                        <p>
                                            <span style={{marginRight: "8%", color: "green"}}>Callsign: WX4TYS </span> 
                                            <span style={{marginRight: "8%", color: 'lightblue'}}>&nbsp;Name: Skywarn </span>
                                            <span style={{color: 'red'}}>&nbsp;Report: loc knoxville. tor. fun. ha 5. dmg trees down all over.</span>
                                        </p>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}
                    </div>
                    {alrt.show && (
                        <Row className='d-flex position-absolute bottom-0' style={{width: '100%'}}>
                            <Col className='justify-content-center text-center'>
                                <Alert style={{zIndex: 1}} variant='danger'>
                                    <h2>{alrt.message}</h2>
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    {distNet.data.status !== 'Inactive' && (
                        <div className='d-flex justify-content-center'>
                            <Card className='net-card'>
                                <Card.Body className='text-black'>
                                    <Row>
                                        <Col md={2}>
                                            <FloatingLabel controlId="floatingTextarea2" label="Callsign">
                                                <Form.Control 
                                                    size='sm' 
                                                    type='text'
                                                    value={formData.callsign} 
                                                    onChange={e => setFormData({
                                                        ...formData, 
                                                        callsign: e.target.value.toUpperCase()
                                                    })} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={2}>
                                            <FloatingLabel controlId="floatingTextarea2" label="Name">
                                                <Form.Control 
                                                    size='sm' 
                                                    type='text' 
                                                    value={formData.name} 
                                                    disabled={nameFilled}
                                                    onChange={e => setFormData({
                                                        ...formData, 
                                                        name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).toLowerCase()
                                                    })} 
                                                />
                                            </FloatingLabel>
                                        </Col>
                                        <Col md={8}>
                                            <FloatingLabel controlId="floatingTextarea2" label="Report">
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Report"
                                                    style={{ height: '58px' }}
                                                    value={formData.report}
                                                    name="report"
                                                    onChange={e => setFormData({
                                                        ...formData, report: e.target.value
                                                    })}
                                                />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    )}
                </div>
            )}
          <Row>
          {(!loading && distNet.show) && (
            <Container>
                <Reports
                    netId={distNet.data}
                    socket={socket}
                />
                <TableContainerReverseSort 
                    data={checkins}
                    columns={columns}
                />
            </Container>
          )}
        </Row>
        </Modal.Body>
      </Modal>
    );
};

export default ViewNet