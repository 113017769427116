import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Active from '../../images/red.png';
import Inactive from '../../images/green.png';
import Standby from '../../images/yellow.png';
import Loading from '../loading';
import DistStat from './DistStat';
import DistrictInfo from './DistrictInfo';

const Districts = ({ districts, socket, currentUser }) => {
    const [showDistInfo, setShowDistInfo] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const handleShowDistInfo = (district) => {
        setSelectedDistrict(district);
        setShowDistInfo(true);
    };

    return districts.length ? (
        <Col>
            <div className="col-md-12 d-flex justify-content-center">
                <div className="card shadow-lg">
                    <div className="card-body justify-content-center">
                        <h3 className="card-title text-center">District Status</h3>
                        <Row className="justify-content-center">
                            {districts
                                .sort((a, b) => (a.district > b.district ? 1 : -1))
                                .map((d) => (
                                    <Col key={d._id} md={3}>
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <div
                                                onClick={() => handleShowDistInfo(d)}
                                                className="card shadow-lg cursor-pointer"
                                            >
                                                <div className="card-body justify-content-center">
                                                    <h5 className="card-title text-center">
                                                        <div>District {d.district}</div>
                                                        <div className="mt-2">
                                                            {d.status === 'Inactive' ? (
                                                                <img className="status-icon" src={Inactive} alt="Inactive" />
                                                            ) : d.status === 'Standby' ? (
                                                                <img className="status-icon" src={Standby} alt="Standby" />
                                                            ) : (
                                                                <img className="status-icon" src={Active} alt="Active" />
                                                            )}
                                                        </div>
                                                    </h5>
                                                    <div className="text-center">
                                                        <span>Status: </span>
                                                        <span>{d.status}</span>
                                                    </div>
                                                    {d.lastRepeater && (
                                                        <div className="text-center">
                                                            <span>Repeater: </span>
                                                            <span>{d.lastRepeater} MHz</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                        </Row>
                    </div>
                </div>
            </div>
            {selectedDistrict && (
                <DistrictInfo
                    show={showDistInfo}
                    setShow={setShowDistInfo}
                    district={selectedDistrict}
                    socket={socket}
                    currentUser={currentUser}
                />
            )}
        </Col>
    ) : (
        <Loading />
    );
};

export default Districts;
