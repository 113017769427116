
import React, { Fragment, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Editor from './TextEditor/Editor';
import NewEditor from './TextEditor/NewEditor';
import Loading from './loading';

const Articles = ({isLoading, data, currentUser, dispatchPosts, type, socket}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        !isLoading ? (
            <Fragment>
                <Row>
                    {data.sort((a, b) => a.order > b.order ? 1 : -1).map(p => (
                        (p.type === type &&
                                <Col key={p._id} md={p.size ? p.size.value : 12} sm={12}>
                                    <div style={{height: '100%'}} className="d-flex justify-content-center">
                                        <Card className="shadow-lg">
                                            <Card.Body className="justify-content-center">
                                                <h3 className="card-title text-center">
                                                    {p.title}
                                                </h3>
                                                <div dangerouslySetInnerHTML={{__html: p.text}} />
                                                {(currentUser.isAuthenticated && currentUser.user.isAdmin > 9 && p.type === type) && (
                                                    <Editor
                                                        dispatchPosts={dispatchPosts}
                                                        currentUser={currentUser}
                                                        post={p}
                                                        socket={socket}
                                                    />
                                                )}
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            
                        )
                    ))}
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center'>
                        {(currentUser.isAuthenticated && currentUser.user.isAdmin > 8 && currentUser.adminSettings) && (
                            <Card className='text-center justify-content-center cursor-pointer shadow-lg'>
                                <NewEditor
                                    dispatchPosts={dispatchPosts}
                                    currentUser={currentUser}
                                    type={type}
                                    socket={socket}
                                />
                            </Card>
                        )}
                    </Col>
                </Row>
            </Fragment>
        ) : (
            <Loading />
        )
    );
};

export default Articles