import React from 'react';
import {Row, Container, Col} from 'react-bootstrap';
import skywarnEye from '../images/loading.gif'

const Loading = () => {

    

   return (
        <Container style={{marginTop: '23vh'}} className="pb-1 mb-5">
            <Row className="pt-3 mb-5 text-center">
                <Col>
                    <img className='img-fluid' src={skywarnEye} />
                </Col>
            </Row>
        </Container>
   )
};

export default Loading;
